import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbItem, Pagination, PaginationInitialState } from '@peca/design-system';
import { Subject } from 'rxjs';
import { Async } from '@peca/backoffice/utils/async.util';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { BuyerNaturalPersonService } from '../../../services/buyer-natural-person.service';
import { BuyerNaturalPersonQuery } from '../../../models/buyer-natural-person-query.model';
import { BuyerNaturalPersonResponse } from '../../../models/buyer-natural-person-response.model';
import { PersistQueryService } from '@peca/commons';

@Component({
  selector: 'peca-buyer-natural-person-list',
  templateUrl: './buyer-natural-person-list.component.html',
  styleUrls: ['./buyer-natural-person-list.component.scss'],
})
export class BuyerNaturalPersonListComponent implements OnInit {
  breadcrumb: BreadcrumbItem[];
  buyerService: BuyerNaturalPersonService;
  pagination$: Subject<Pagination>;
  lastQuery: BuyerNaturalPersonQuery;
  buyers: Async<BuyerNaturalPersonResponse[]>;
  router: Router;
  route: ActivatedRoute;
  form: FormGroup;
  persistQueryService: PersistQueryService;
  private readonly searchFormInitialValue = {
    search: '',
    active: '',
  };

  constructor() {
    this.breadcrumb = [{ label: 'Compradores', path: '/compradores' }, { label: 'Pessoa Física' }];
    this.buyerService = inject(BuyerNaturalPersonService);
    this.pagination$ = new Subject<Pagination>();
    this.lastQuery = { ...PaginationInitialState, sort: 'audit.createdAt,desc' };
    this.buyers = new Async<BuyerNaturalPersonResponse[]>();
    this.router = inject(Router);
    this.route = inject(ActivatedRoute);
    this.persistQueryService = inject(PersistQueryService);

    this.form = new FormGroup({
      search: new FormControl(''),
      active: new FormControl(''),
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, active, status, sort } = JSON.parse(atob(params['q']));
      this.form.patchValue({
        search: search || '',
        active: active || '',
        sort: sort || 'audit.createdAt,desc',
      });
    });

    const persistedQuery = this.persistQueryService.read<BuyerNaturalPersonQuery>('peca-buyer-natural-person-list');
    const query = persistedQuery || this.lastQuery;
    this.fetchBuyers(query);
  }

  fetchBuyers(query: BuyerNaturalPersonQuery) {
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-buyer-natural-person-list', this.lastQuery);
    this.buyers.loading();
    this.buyerService.readAll(query).subscribe({
      next: (response) => {
        this.paginate(response);
        this.buyers.loaded(response.items);
      },
      error: () => {
        this.buyers.failed();
      },
    });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  onClickDetails(buyer: BuyerNaturalPersonResponse) {
    this.router.navigate(['compradores', 'pessoa-fisica', buyer.id]);
  }

  onClickSearch() {
    const { search, active } = this.form.value;
    const query = { ...PaginationInitialState, sort: 'audit.createdAt,desc', search, active };
    this.persistQueryService.rewriteQueryString('peca-buyer-natural-person-list', query);
    this.fetchBuyers(query);
    this.pagination$.next(PaginationInitialState);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onClickSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }

  onEventRetry() {
    this.fetchBuyers(this.lastQuery);
  }

  onEventPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchBuyers(query);
  }
}
