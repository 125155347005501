import { Subject, takeUntil } from 'rxjs';
import { Component, EventEmitter, OnDestroy, Output, ViewChild, inject } from '@angular/core';
import { AnalyticsService } from '@peca/commons';
import { Async } from '@peca/backoffice/utils/async.util';
import { ModalComponent } from '@peca/design-system';
import { ToastService, isNull } from '@peca/commons';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

import { OrderProduct } from '../../models/order-product.modal';
import { OrderService } from '../../services/order.service';
import { OrderReturnForm } from '../../forms/order-return.form';
import { Order } from '../../models/order.model';

type OrderReturnProduct = OrderProduct & { quantityReturned: number };

@Component({
  selector: 'peca-order-return',
  templateUrl: './order-return.component.html',
  styleUrls: ['./order-return.component.scss'],
})
export class OrderReturnComponent implements OnDestroy {
  @ViewChild('modal')
  modal!: ModalComponent;
  @Output()
  adjust: EventEmitter<void>;
  products: Async<OrderReturnProduct[]>;
  analytics: AnalyticsService;
  orderId: string;
  orderService: OrderService;
  destroyRef$: Subject<void>;
  quantityError: string | null;
  confirmable: ConfirmableActionService;
  toast: ToastService;
  orderReturnForm: OrderReturnForm;
  order: Order | null;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.orderService = inject(OrderService);
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.orderId = '';
    this.adjust = new EventEmitter<void>();
    this.products = new Async<OrderReturnProduct[]>();
    this.destroyRef$ = new Subject<void>();
    this.quantityError = null;
    this.orderReturnForm = new OrderReturnForm();
    this.order = null;
  }

  get payment() {
    if (!this.order?.payments?.length) return null;

    const [payment] = this.order.payments;

    return payment;
  }

  ngOnDestroy(): void {
    this.destroyRef$.next();
    this.destroyRef$.complete();
  }

  open(order: Order) {
    this.modal.open();
    this.orderId = order.id;
    this.order = order;
    this.fetchOrderProducts(order.id);
  }

  close() {
    this.modal.close();
    this.orderId = '';
    this.order = null;
    this.destroyRef$.next();
    this.products.clear();
    this.quantityError = null;
    this.orderReturnForm = new OrderReturnForm();
  }

  fetchOrderProducts(orderId: string) {
    this.products.loading();
    this.orderService
      .fetchProductsFromOrder(orderId)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: (products) => {
          const bankSlipPayment = this.isBankSlipPayment();
          const isThirdPartyTransferPayment = this.verifyThirdPartyTransferPayment();

          this.products.loaded(products.map((p) => ({ ...p, quantityReturned: 0 })));
          this.orderReturnForm.controls.bankSlipPayment.setValue(bankSlipPayment);
          this.orderReturnForm.controls.isThirdPartyTransferPayment.setValue(isThirdPartyTransferPayment);

          if (bankSlipPayment) {
            this.orderReturnForm.controls.creditRefund.setValue(true);
            this.orderReturnForm.controls.creditRefund.disable();
          } else if (isThirdPartyTransferPayment) {
            this.orderReturnForm.controls.creditRefund.setValue(false);
            this.orderReturnForm.controls.creditRefund.disable();
          } else {
            this.orderReturnForm.controls.creditRefund.enable();
          }
        },
        error: () => this.products.failed(),
      });
  }

  onDismiss() {
    this.close();
  }

  onChangeQuantity(product: OrderReturnProduct) {
    const { id, description, brand, partNumber, quantity, quantityReturned } = product;
    const returned = this.orderReturnForm.findProductReturned(product);

    this.quantityError = null;

    if (isNull(quantityReturned) || quantityReturned < 0 || quantityReturned > quantity) {
      this.orderReturnForm.returned = this.orderReturnForm.returned.filter((p: any) => p.id !== id);
      this.quantityError = 'Informe a quantidade corretamente';
      return;
    }

    if (quantityReturned === 0) {
      this.orderReturnForm.returned = this.orderReturnForm.returned.filter((p) => p.id !== id);
      return;
    }

    if (returned) {
      returned.quantity = quantityReturned;
      return;
    }

    this.orderReturnForm.addProductReturned({
      id,
      description,
      brand,
      partNumber,
      quantity: quantityReturned,
    });
  }

  onSubmit() {
    const message = 'Você está está criando uma devolução de produtos.';
    this.confirmable.confirm(message, null, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.orderService.createOrderReturn(this.orderId, this.orderReturnForm.group.getRawValue()).subscribe({
        next: () => {
          this.toast.success('Devolução criada com sucesso.');
          this.adjust.next();
          this.close();
        },
        error: () => this.toast.failure('Não foi possível criar a devolução.'),
      });
    });
  }

  isBankSlipPayment() {
    const referenceName = this.payment?.paymentMethod.referenceName || '';
    return referenceName.startsWith('bank-slip') && referenceName !== 'bank-slip_0';
  }

  verifyThirdPartyTransferPayment() {
    const referenceName = this.payment?.paymentMethod.referenceName || '';
    return referenceName === 'third-party-transfer';
  }
}
