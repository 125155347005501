import { Routes } from '@angular/router';

import { SellerListComponent } from './components/seller-list/seller-list.component';
import { SellerFormComponent } from './components/seller-form/seller-form.component';
import { SellerDetailsComponent } from './components/seller-details/seller-details.component';
import { SellerDetailsProductsComponent } from './components/seller-details-products/seller-details-products.component';
import { SellerDetailsBranchesComponent } from './components/seller-details-branches/seller-details-branches.component';
import { SellerSettingsComponent } from './components/seller-settings/seller-settings.component';

export const routes: Routes = [
  {
    path: ':sellerId/filiais/nova',
    component: SellerFormComponent,
    data: { operation: 'CREATE_BRANCH' },
  },
  {
    path: ':sellerId/filiais/:branchId',
    component: SellerFormComponent,
    data: { operation: 'UPDATE_BRANCH' },
  },
  {
    path: 'novo',
    component: SellerFormComponent,
    data: { operation: 'CREATE_SELLER' },
  },
  {
    path: ':sellerId',
    component: SellerDetailsComponent,
    children: [
      {
        path: 'filiais',
        component: SellerDetailsBranchesComponent,
      },
      {
        path: 'produtos',
        component: SellerDetailsProductsComponent,
      },
      {
        path: 'configurações',
        component: SellerSettingsComponent,
      },
      {
        path: '',
        redirectTo: 'filiais',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    component: SellerListComponent,
  },
];

export const SellersModuleLoad = () => import('./sellers.module').then(({ SellersModule }) => SellersModule);
