<pds-module-header class="order-list" title="Devoluções" ctaLabel="Voltar" modifier="outlined" ctaPath="/devolucoes">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <ng-container *ngIf="order.state$ | async; let order">
    <ng-container *ngIf="order.data">
      <div class="order-details-card">
        <div class="order-details-card__header">
          <div class="order-details-card__header__col --part-number">{{ order.data.order.commerceReference }}</div>
          <div class="order-details-card__header__col --status">
            <strong>Devolução</strong>
            <span pdsChip>{{ order.data.status | status }}</span>
          </div>
          <div class="order-details-card__header__col --status">
            <strong>Pagamento do estorno</strong>
            <span pdsChip>{{ order.data.payment | status }}</span>
          </div>
          <div class="order-details-card__header__col --datetime">{{ order.data.audit.createdAt | date : 'dd/MM/yyyy HH:mm' }}</div>
        </div>

        <div class="order-details-card__content">
          <div class="order-summary">
            <div class="order-summary__row">
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">NFe de Devolução</h4>
                  <div class="order-summary__headline__value">
                    @if (order.data | invoiceWarning) {
                    <span pdsChip><span class="icon">warning</span>Pendente</span>
                    } @else {
                    <span pdsChip="secondary">{{ order.data.invoice ? 'Nº ' + order.data.invoice : '-' }}</span>
                    }
                  </div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Pedido de venda</h4>
                  <div class="order-summary__headline__value">
                    <a [routerLink]="['/vendas', order.data.order.id]" pdsChipLink target="_blank">{{ order.data.order.commerceReference }}</a>
                  </div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">NFe Pedido de venda</h4>
                  <div class="order-summary__headline__value">
                    @if (order.data.order.invoice?.nfNumber) {
                    <span pdsChip>{{ order.data.order.invoice?.nfNumber }}</span>
                    } @else {
                    <span>-</span>
                    }
                  </div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Método de pagamento da venda</h4>
                  <div class="order-summary__headline__value">
                    <span pdsChip>{{ order.data.paymentMethod.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-summary__row">
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Un. de negócio</h4>
                  <div class="order-summary__headline__value">{{ order.data.businessUnit }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Razão Social</h4>
                  <div class="order-summary__headline__value">{{ order.data.buyer.companyName }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">CNPJ</h4>
                  <div class="order-summary__headline__value">{{ order.data.buyer.cnpj }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Endereço</h4>
                  <div class="order-summary__headline__value">
                    {{ (order.data.buyer.shippingAddress | address) || '-' }}
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <h3>Detalhes da Devolução</h3>

            <div class="order-summary__row">
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Cliente está com a peça?</h4>
                  <div class="order-summary__headline__value">{{ order.data.customerHavePart ? 'Sim' : 'Não' }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Categoria da devoluçao</h4>
                  <div class="order-summary__headline__value">{{ order.data.category | category }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Motivo</h4>
                  <div class="order-summary__headline__value">{{ order.data.reason | reason }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Justificativa</h4>
                  <div class="order-summary__headline__value">{{ order.data.justification || '-' }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="order-details-pricing">
            <div class="order-details-pricing__header">
              <h2>Método de estorno</h2>
              <div class="gap-4 flex">
                @if (methods.isBankSlip) { @if(methods.cancelation && !methods.remaining && !methods.manual && !methods.credit) {
                <span pdsChip="secondary">Cancelamento de boletos</span>
                } @if(methods.cancelation && methods.remaining && !methods.manual && !methods.credit) {
                <span pdsChip="secondary">Ajuste de boletos</span>
                } @if(methods.cancelation && !methods.remaining && !methods.manual && methods.credit) {
                <span pdsChip="secondary">Cancelamento de boletos</span>
                <span pdsChip="secondary">Crédito em conta corrente</span>
                } @if(methods.cancelation && !methods.remaining && methods.manual && !methods.credit) {
                <span pdsChip="secondary">Cancelamento de boletos</span>
                <span pdsChip="secondary">Estorno manual</span>
                } @if(!methods.cancelation && !methods.remaining && !methods.manual && methods.credit) {
                <span pdsChip="secondary">Crédito em conta corrente</span>
                } @if(!methods.cancelation && !methods.remaining && methods.manual && !methods.credit) {
                <span pdsChip="secondary">Estorno manual</span>
                }} @else if (methods.creditRefund) {
                <span pdsChip="secondary">Crédito em conta corrente</span>
                } @else {
                <span pdsChip="secondary">{{ order.data.paymentMethod.name }}</span>
                }
              </div>
            </div>

            <div class="flex space-between flex-1 mb-12">
              <div class="order-details-pricing__col">
                <div class="order-details-pricing__col__label">Frete</div>
                <div class="order-details-pricing__col__value">{{ order.data.freightRefundAmount || 0 | currency : 'BRL' }}</div>
              </div>
              <div class="order-details-pricing__col">
                <div class="order-details-pricing__col__label">Produtos devolvidos</div>
                <div class="order-details-pricing__col__value">{{ order.data.productsRefundAmount || 0 | currency : 'BRL' }}</div>
              </div>
              <div class="order-details-pricing__col">
                <div class="order-details-pricing__col__label">Valor da devolução</div>
                <div class="order-details-pricing__col__value">{{ order.data.totalRefundAmount | currency : 'BRL' }}</div>
              </div>
            </div>
            <div class="flex space-between flex-1">
              @if(order.data.creditRefundAmount) {
              <div class="order-details-pricing__col">
                <div class="order-details-pricing__col__label">Crédito em conta corrente</div>
                <div class="order-details-pricing__col__value">{{ order.data.creditRefundAmount | currency : 'BRL' }}</div>
              </div>
              } @if (order.data.cancelationRefundAmount) {
              <div class="order-details-pricing__col">
                <div class="order-details-pricing__col__label">Cancelamento de boletos</div>
                <div class="order-details-pricing__col__value">{{ order.data.cancelationRefundAmount | currency : 'BRL' }}</div>
              </div>
              } @if (order.data.manualRefundAmount){
              <div class="order-details-pricing__col">
                <div class="order-details-pricing__col__label">Estorno manual</div>
                <div class="order-details-pricing__col__value">{{ order.data.manualRefundAmount | currency : 'BRL' }}</div>
              </div>
              } @if (order.data.remainingAmount){
              <div class="order-details-pricing__col">
                <div class="order-details-pricing__col__label">Ajuste de boletos</div>
                <div class="order-details-pricing__col__value">{{ order.data.remainingAmount | currency : 'BRL' }}</div>
              </div>
              }
            </div>
          </div>

          @if (order.data.payment === 'PENDING' && order.data.manualRefundAmount) {
          <pds-alert title="Pagamento Manual" type="error">
            <div>
              Esta devolução possuí um pagamento de estorno manual pendente no valor de <strong>{{ order.data.manualRefundAmount | currency : 'BRL' }}</strong>
            </div>
          </pds-alert>
          }

          <pds-tabs [tabs]="tabs" (selected)="onTabSelected($event)">
            <router-outlet />
          </pds-tabs>

          <div class="order-details-card__content__observations">
            <div class="order-details-card__content__observations__col">
              <h4>Observações internas</h4>
              <p>{{ order.data.observation || '-' }}</p>
            </div>
            <div class="order-details-card__content__observations__col">
              <h4>Imagens anexadas</h4>
              <div class="flex gap-2 wrap">
                @for (file of order.data.files; track $index) {
                <span [pdsChip]="file.status === 'UPLOADED' ? 'secondary' : 'default'" (click)="onClickPreview(file)" [icon]="file.status === 'UPLOADED' ? 'open_in_new' : 'pending'">
                  <span class="text-truncate">{{ file.name }}</span>
                </span>
                } @empty {
                <span>-</span>
                }
              </div>
            </div>
          </div>

          <hr />

          <pds-form-actions>
            <button type="button" title="Anaxar imagem" pdsButton (click)="onClickAttach()">Anexar imagem</button>
            <button type="button" title="Alterar detalhes" pdsButton (click)="onClickOpenForm()">Alterar detalhes</button>
          </pds-form-actions>
        </div>
      </div>

      <peca-document-preview #documentPreview (delete)="onDeleteEvent($event)" />
      <peca-orders-return-form-modal #form (submit)="onSubmitForm($event)" />
      <peca-file-upload #upload (upload)="onUploadFile($event)" />
    </ng-container>

    <ng-container *ngIf="order.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="order.failed">
      <peca-retry context="detalhes da devolução" (retry)="onRetryEvent()" />
    </ng-container>
  </ng-container>
</pds-module-header>
