import { Component, OnDestroy, OnInit, SecurityContext, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ConfirmableActionService } from '../../services/confirmable-action.service';
import { ConfirmableAction } from '../../models/confirmable-action.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'peca-confirmable-action-2',
  templateUrl: './confirmable-action.component.html',
  styleUrls: ['./confirmable-action.component.scss'],
})
export class ConfirmableActionComponent implements OnInit, OnDestroy {
  destroyRef$: Subject<void>;
  service: ConfirmableActionService;
  action: ConfirmableAction<any> | null;
  sanitazer: DomSanitizer;

  constructor() {
    this.service = inject(ConfirmableActionService);
    this.sanitazer = inject(DomSanitizer);
    this.destroyRef$ = new Subject();
    this.action = null;
  }

  ngOnInit() {
    this.service.message.pipe(takeUntil(this.destroyRef$)).subscribe((action) => {
      const sanitazedMessage = this.sanitazer.sanitize(SecurityContext.HTML, action?.message);
      action.message = sanitazedMessage || '';
      this.action = action;
    });
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  onClickConfirm(confirmed: boolean) {
    const payload = this.action?.payload;
    this.action?.confirmation.next({ confirmed, payload });
    this.action = null;
  }
}
