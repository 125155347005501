<ng-container *ngIf="sellerSettings$ | async; let sellerSettings">
  <ng-container *ngIf="!sellerSettings.loading && !sellerSettings.failure">
    <pds-form [formGroup]="settingForm.group">
      <pds-form-section title="API">
        <pds-form-row grid="1-1">
          <pds-form-field [errors]="this.settingForm.controls.clientId.invalid ? [{ message: 'Somente letras, espaços e hífens são permitidos.' }] : null" label="Client ID">
            <input type="text" formControlName="clientId" />
          </pds-form-field>
          <pds-form-field action="refresh" label="Client Secret" (clicked)="onClickRefreshClientSecret()">
            <input [type]="this.settingForm.enabled ? 'text' : 'password'" readonly formControlName="clientSecret" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="FTP (Estoque/Preço)">
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Host">
            <input type="text" formControlName="host" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)" />
          </pds-form-field>
          <pds-form-field label="Usuário">
            <input type="text" formControlName="username" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)" />
          </pds-form-field>
          <pds-form-field label="Senha">
            <input [type]="this.settingForm.enabled ? 'text' : 'password'" formControlName="password" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)" />
          </pds-form-field>
        </pds-form-row>
        <pds-form-row>
          <pds-form-field label="Tipo de Integração">
            <select formControlName="type" (change)="valiFTPCredentialsdRequired($event)" (input)="valiFTPCredentialsdRequired($event)">
              <option value="">Selecione</option>
              <option value="FULL">{{ 'FULL' | typeIntegration }}</option>
              <option value="INCREMENTAL">{{ 'INCREMENTAL' | typeIntegration }}</option>
            </select>
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Webhooks">
        <ng-container *ngIf="webHooks">
          <ng-container formArrayName="webHooks" *ngFor="let webhook of webHooks.controls; let i = index">
            <div class="row-webhooks" [formGroupName]="i">
              <pds-form-field class="service" [label]="'Serviço ' + (i + 1)">
                <select formControlName="service">
                  <option value="">Selecione</option>
                  <option value="NEW_PURCHASE_ORDER">{{ 'NEW_PURCHASE_ORDER' | webHookService }}</option>
                  <option value="CANCELED_PURCHASE_ORDER">{{ 'CANCELED_PURCHASE_ORDER' | webHookService }}</option>
                </select>
              </pds-form-field>
              <pds-form-field class="url" label="URL">
                <input type="text" formControlName="url" />
              </pds-form-field>
              <pds-form-field label="">
                <button [disabled]="settingForm.group.disabled" (click)="onClickRemoveWebHook(i)" class="buttonDel" pdsButton="icon">
                  <span class="material-symbols-outlined">remove</span>
                </button>
              </pds-form-field>
            </div>
          </ng-container>
        </ng-container>
        <button [disabled]="settingForm.disabled" pdsButton title="Adicionar Webhook" (click)="onClickAddWebHook()">
          <span class="material-symbols-outlined">add</span>
        </button>
      </pds-form-section>

      <hr />

      <pds-form-actions>
        <ng-container>
          @if (settingForm.enabled) {
          <button pdsButton="primary" (click)="onClickCancel()">Cancelar</button>
          <button pdsButton [disabled]="settingForm.group.invalid" (click)="onClickSave()">Salvar</button>
          }@else {
          <button pdsButton (click)="onClickEnable()">Editar</button>
          }
        </ng-container>
      </pds-form-actions>
    </pds-form>
  </ng-container>

  <ng-container *ngIf="sellerSettings.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="sellerSettings.failure">
    <ng-container>
      <peca-retry context="dados do vendedor" (retry)="onRetry()" />
    </ng-container>
  </ng-container>
</ng-container>
