import { Router } from '@angular/router';
import { ToastService, PersistQueryService } from '@peca/commons';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Pagination, PaginationInitialState, BreadcrumbItem } from '@peca/design-system';

import { AsyncState } from '../../../../core/models/async-state.model';
import { Pageable } from '../../../../core/models/pageable.model';
import { Seller } from '../../models/seller.model';
import { SellerService } from '../../services/seller.service';
import { SellerQuery } from '../../services/seller-query.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

@Component({
  selector: 'peca-seller-list',
  templateUrl: './seller-list.component.html',
  styleUrls: ['./seller-list.component.scss'],
})
export class SellerListComponent implements OnInit, OnDestroy {
  sellerService: SellerService;
  sellers$: BehaviorSubject<AsyncState<Array<Seller>>>;
  pagination$: BehaviorSubject<Pagination>;
  destroyRef$: Subject<void>;
  router: Router;
  toastService: ToastService;
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Vendedores', path: '/vendedores' }];
  lastQuery: SellerQuery;
  confirmable: ConfirmableActionService;
  persistQueryService: PersistQueryService;

  constructor() {
    this.router = inject(Router);
    this.toastService = inject(ToastService);
    this.sellerService = inject(SellerService);
    this.confirmable = inject(ConfirmableActionService);
    this.persistQueryService = inject(PersistQueryService);
    this.sellers$ = new BehaviorSubject<AsyncState<Array<Seller>>>({
      loading: true,
    });
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.lastQuery = { ...PaginationInitialState, sort: 'audit.createdAt,desc', page: 0 };
    this.destroyRef$ = new Subject<void>();
  }

  ngOnInit() {
    const persitedQuery = this.persistQueryService.read<SellerQuery>('peca-seller-list');
    const query = persitedQuery || this.lastQuery;

    this.fetchSellers(query);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  // fetch sellers lifecycle
  fetchSellers(query: SellerQuery) {
    this.lastQuery = { ...query };
    this.persistQueryService.rewriteQueryString('peca-seller-list', this.lastQuery);
    this.sellers$.next({ loading: true });
    this.sellerService
      .fetchSellers(query)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: this.onFetchSellersSuccess.bind(this),
        error: this.onFetchSellersFailure.bind(this),
      });
  }

  onFetchSellersSuccess(response: Pageable<Seller>) {
    this.paginate(response);
    this.sellers$.next({ data: response.items });
  }

  onFetchSellersFailure(e: unknown) {
    this.sellers$.next({ failure: true });
  }
  // end fetch sellers lifecycle

  // update seller status lifecycle
  updateSellerStatus(seller: Seller) {
    this.sellerService
      .updateSellerStatus(seller.id, seller.active)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: this.onUpdateSellerStatusSuccess.bind(this),
        error: (e: unknown) => this.onUpdateSellerStatusFailure(e, seller),
      });
  }

  onUpdateSellerStatusSuccess() {
    const message = 'O status do vendedor foi atualizado com sucesso.';
    this.toastService.success(message);
  }

  onUpdateSellerStatusFailure(error: unknown, seller: Seller) {
    const message = 'Ocorreu um erro ao atualizar o status do vendedor.';

    this.toastService.failure(message);
    seller.active = !seller.active;
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  handleFilterEvent(query: SellerQuery) {
    this.fetchSellers(query);
  }

  handleSellerStatusChange(seller: Seller) {
    this.confirmable
      .confirm(
        'Você está alterando o status de um vendedor e todas filiais serão alteradas. Caso queira alterar o status de apenas uma filial, acesso os detalhes do vendedor para listar sua filiais.'
      )
      .subscribe(({ confirmed }) => {
        if (!confirmed) {
          seller.active = !seller.active;
          return;
        }

        this.updateSellerStatus(seller);
      });
  }

  handleDetailsClickEvent(seller: Seller) {
    this.router.navigate(['/vendedores', seller.id]);
  }

  handlePaginateEvent(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchSellers(query);
  }
}
