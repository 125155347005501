import { BehaviorSubject, Subject } from 'rxjs';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BreadcrumbItem, MultipleItem, Pagination, PaginationInitialState } from '@peca/design-system';
import { Async } from '@peca/backoffice/utils/async.util';
import { Pageable } from '@peca/backoffice/models/pageable.model';

import { ShippingQuery } from '../../models/shipping-query.model';
import { ShippgingService } from '../../services/shipping.service';
import { ShippingListResponse } from '../../models/shipping-list-response.model';
import { ShippingStatusPipe } from '../../pipes/shipping-status.pipe';
import { ShippingStatus } from '../../models/shipping-status.model';
import { ActivatedRoute } from '@angular/router';
import { PersistQueryService } from '@peca/commons';
import { CarrierService } from '../../../carriers/services/carrier.service';

@Component({
  templateUrl: './shipping-list.component.html',
  styleUrls: ['./shipping-list.component.scss'],
})
export class ShippingListComponent implements OnInit, OnDestroy {
  shippingService: ShippgingService;
  breadcrumb: BreadcrumbItem[];
  status$: BehaviorSubject<MultipleItem[]>;
  lastQuery: ShippingQuery;
  shippings: Async<ShippingListResponse[]>;
  carriers: any[];
  pagination$: BehaviorSubject<Pagination>;
  destroyRef$: Subject<void>;
  searchForm: FormGroup;
  persistQueryService: PersistQueryService;
  route: ActivatedRoute;
  private readonly searchFormInitialValue = {
    status: [],
    date: { startDate: '', endDate: '' },
    search: '',
    carrierIds: '',
  };

  constructor() {
    this.shippingService = inject(ShippgingService);
    this.persistQueryService = inject(PersistQueryService);
    this.route = inject(ActivatedRoute);
    this.breadcrumb = [{ label: 'Entregas', path: '/entregas' }];
    this.status$ = new BehaviorSubject<MultipleItem[]>(this.getStatusSource());
    this.lastQuery = this.lastQuery = { sort: 'audit.createdAt,desc', ...PaginationInitialState };
    this.shippings = new Async<ShippingListResponse[]>();
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.destroyRef$ = new Subject<void>();
    this.searchForm = new FormGroup({
      status: new FormControl([]),
      date: new FormControl({ startDate: '', endDate: '' }),
      search: new FormControl(''),
      carrierIds: new FormControl([]),
    });
    this.carriers = [];
  }

  get shippings$() {
    return this.shippings.state$;
  }

  ngOnInit() {
    this.searchForm.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.shippingService.suggestCarriers('').subscribe({ next: (source) => (this.carriers = source) });
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, startDate, endDate, status, sort, carrierIds } = JSON.parse(atob(params['q']));
      this.searchForm.patchValue({
        search: search || '',
        date: { startDate, endDate },
        status: status || '',
        sort: sort || 'audit.createdAt,desc',
        carrierIds: carrierIds || '',
      });
    });

    const persitedQuery = this.persistQueryService.read<ShippingQuery>('peca-shipping-list');
    const query = persitedQuery || this.lastQuery;
    this.fetchReturns(query);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  fetchReturns(query: ShippingQuery) {
    this.lastQuery = { ...query };
    this.persistQueryService.rewriteQueryString('peca-shipping-list', this.lastQuery);
    this.shippings.loading();
    this.shippingService.fetchShippings(query).subscribe({
      next: (data) => {
        this.paginate(data);
        this.shippings.loaded(data.items);
      },
      error: () => this.shippings.failed(),
    });
  }

  onClickSearch() {
    if (this.searchForm.invalid) return;

    const { status, date, carrierIds } = this.searchForm.value;
    const params = {
      status: status.length ? status : null,
      startDate: date?.startDate || null,
      endDate: date?.endDate || null,
      carrierIds: carrierIds || null,
    };
    const query = { ...params, sort: 'audit.createdAt,desc', ...PaginationInitialState };

    this.fetchReturns(query);
  }

  clearFilters() {
    this.searchForm.reset(this.searchFormInitialValue);
    this.onClickSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.searchForm.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.searchForm.markAsPristine();
    return this.searchForm.markAsDirty();
  }

  onRetry() {
    this.fetchReturns(this.lastQuery);
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchReturns(query);
  }

  private getStatusSource() {
    const status: ShippingStatus[] = ['PENDING', 'SEARCHING_CARRIER', 'COLLECTION_ROUTE', 'SHIPPING_ROUTE', 'CANCELED', 'FAILED', 'DELIVERED'];
    const pipe = new ShippingStatusPipe();

    return status.map((i) => ({ id: i, label: pipe.transform(i) as string }));
  }
}
