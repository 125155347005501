import { Pipe, PipeTransform } from '@angular/core';
import { LogStatus } from '@peca/backoffice/models/logs/log-type-status';
import { LogDirectionType } from '@peca/backoffice/models/logs/log.model';

@Pipe({
  standalone: true,
  name: 'logTypeStatus',
})
export class LogTypeStatusPipe implements PipeTransform {
  transform(type: LogDirectionType) {
    switch (type as LogStatus) {
      case 'PROCESSING_PAYMENT':
        return 'Processando Pagamento';
      case 'PAYMENT_ACCEPT':
        return 'Pagamento Aprovado';
      case 'PAYMENT_REFUSED':
        return 'Pagamento Recusado';
      case 'WAITING_FOR_SELLER':
        return 'Aguardando Vendedor';
      case 'IN_SEPARATION':
        return 'Em Separação';
      case 'AWAITING_COLLECTION':
        return 'Aguardando Coleta';
      case 'ON_ROUTE':
        return 'Coletado Pela Transportadora';
      case 'MISPLACED':
        return 'Extraviado';
      case 'DELIVERED':
        return 'Entregue';
      case 'PROCESSING_RETURN':
        return 'Processando Devolução';
      case 'PARTIAL_RETURN':
        return 'Devolvido Parcialmente';
      case 'TOTAL_RETURN':
        return 'Devolvido';
      case 'CANCELED':
        return 'Cancelado';
      case 'REQUIRES_MANUAL_ADJUSTMENT':
        return 'Aguardando Ajustes';
      case 'PARTIAL_DELIVERED':
        return 'Entregue Parcialmente';
      case 'PENDING':
        return 'Em análise';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Rejeitado';
      case 'PAID':
        return 'Pago';
      case 'WAITING_CONFIRMATION':
        return 'Aguardando Confirmação';
      case 'IN_TRANSIT':
        return 'Em Trânsito';
      case 'UNDER_ANALYSIS':
        return 'Em Análise';
      case 'PROCESSING':
        return 'Processando';
      case 'REFUSED':
        return 'Recusado';
      case 'DISPATCHED':
        return 'Despachado';
      default:
        return type;
    }
  }
}
