import { Component, inject } from '@angular/core';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { BehaviorSubject, map, Observable, throwError } from 'rxjs';
import { SettingsSeller } from '../../models/seller-settings.model';
import { FormArray } from '@angular/forms';
import { SellerSettingService } from '../../services/seller-settings.service';
import { ActivatedRoute } from '@angular/router';
import { SellerSettingForm } from './seller-setting-form';
import { ToastService } from '@peca/commons';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

@Component({
  selector: 'peca-seller-settings',
  templateUrl: './seller-settings.component.html',
  styleUrl: './seller-settings.component.scss',
})
export class SellerSettingsComponent {
  private settingService: SellerSettingService;
  private route: ActivatedRoute;
  private toast: ToastService;
  private confirmable: ConfirmableActionService;

  sellerSettings$: BehaviorSubject<AsyncState<SettingsSeller>>;
  settingForm: SellerSettingForm;

  constructor() {
    this.settingService = inject(SellerSettingService);
    this.confirmable = inject(ConfirmableActionService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);

    this.settingForm = new SellerSettingForm();
    this.sellerSettings$ = new BehaviorSubject<AsyncState<SettingsSeller>>({ loading: true });
  }

  get sellerId$() {
    return this.route.parent?.params.pipe(map((params) => params['sellerId'] as string)) as Observable<string>;
  }

  get webHooks() {
    return this.settingForm.controls.webHooks as FormArray;
  }

  ngOnInit() {
    this.fetchSellerSettings();
  }

  onRetry() {
    this.fetchSellerSettings();
  }

  fetchSellerSettings() {
    this.sellerId$.subscribe({
      next: (sellerId: string) => {
        this.settingService.fetchSellersSettings(sellerId).subscribe({
          next: (settings) => this.onFetchSuccess(settings),
          error: (error) => this.handleFetchError(error),
        });
      },
      error: (error) => this.handleFetchError(error),
    });
  }

  private onFetchSuccess(settings: SettingsSeller): void {
    this.settingForm.setValue(settings);
    this.settingForm.disableForm();
    this.sellerSettings$.next({ loading: false });
  }

  private handleFetchError(error: any): Observable<never> {
    this.sellerSettings$.next({ loading: false, failure: true });
    return throwError(() => error);
  }

  onClickSave() {
    if (this.settingForm.group.invalid) return;

    const values = this.settingForm.values;
    const sellerSetting = {
      apiCredential: {
        clientId: values?.clientId,
      },
      ftpCredential: {
        host: values?.host,
        password: values?.password,
        type: values?.type,
        username: values?.username,
      },
      webhooks: values?.webHooks,
    } as SettingsSeller;

    this.sellerSettings$.next({ loading: true });
    this.sellerId$.subscribe({
      next: (sellerId: string) => {
        this.settingService.updateSettingsSellers(sellerId, sellerSetting).subscribe({
          next: (settings) => this.onSaveSuccess(settings),
          error: () => this.handleSaveError(),
        });
      },
    });
  }

  private onSaveSuccess(settings: SettingsSeller) {
    this.toast.success('Vendedor atualizado com sucesso');
    this.settingForm.setValue(settings);
    this.settingForm.disableForm();
    this.sellerSettings$.next({ loading: false });
  }

  private handleSaveError() {
    this.toast.failure('Falha ao atualizar configurações do vendedor');
    this.fetchSellerSettings();
    this.settingForm.disableForm();
    this.sellerSettings$.next({ loading: false });
  }

  onClickRefreshClientSecret() {
    this.sellerId$.subscribe({
      next: (sellerId: string) => {
        const message = `Você está atualizando as credenciais do vendedor.".`;

        this.confirmable.confirm<string>(message, sellerId, true).subscribe((confirmation) => {
          if (!confirmation.confirmed) return;

          this.settingService.refreshClientSecret(confirmation.payload).subscribe({
            next: () => {
              this.toast.success('Client secret atualizado com sucesso.');
              this.ngOnInit();
            },
            error: () => {
              this.toast.failure('Falha ao atualizar o client secret');
            },
          });
        });
      },
    });
  }

  valiFTPCredentialsdRequired(e: Event) {
    const input = e.target as HTMLInputElement;
    const { host, username, password, type } = this.settingForm.values;

    const value = input.value;
    const isRequired = !!(!!value || host || username || password || type);

    this.settingForm.setFTPCredentialsRequired(isRequired);
  }

  onClickCancel() {
    this.fetchSellerSettings();
    this.settingForm.disableForm();
  }

  onClickRemoveWebHook(index: number) {
    this.settingForm.controls.webHooks.removeAt(index);
  }

  onClickAddWebHook() {
    this.settingForm.addNewWebHook();
  }

  onClickEnable() {
    this.settingForm.enableForm();
  }
}
