import { Pagination, PaginationInitialState, BreadcrumbItem, InsightsService } from '@peca/design-system';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { AnalyticsService, DocumentPreviewComponent, ToastService, PersistQueryService } from '@peca/commons';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { CurrencyPipe } from '@angular/common';
import { OrderService } from '../../services/order.service';
import { Order } from '../../models/order.model';
import { OrderQuery } from '../../models/order-query.model';
import { OrderOperationsValidadorService } from '../../services/order-operations-validador.service';

@Component({
  selector: 'peca-order-list',
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss',
})
export class OrderListComponent implements OnInit, OnDestroy {
  @ViewChild('documentPreview')
  documentPreview!: DocumentPreviewComponent;
  destroyRef$: Subject<void>;
  orderService: OrderService;
  orders$: BehaviorSubject<AsyncState<Order[]>>;
  lastQuery: OrderQuery;
  pagination$: BehaviorSubject<Pagination>;
  router: Router;
  route: ActivatedRoute;
  breadcrumb: BreadcrumbItem[] = [{ label: 'Vendas', path: '/vendas' }];
  toast: ToastService;
  confirmationAction: ConfirmableActionService;
  analytics: AnalyticsService;
  persistQueryService: PersistQueryService;
  orderOperationsValidador: OrderOperationsValidadorService;
  insightsService: InsightsService;
  currencyPipe: CurrencyPipe;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.orderService = inject(OrderService);
    this.router = inject(Router);
    this.toast = inject(ToastService);
    this.confirmationAction = inject(ConfirmableActionService);
    this.route = inject(ActivatedRoute);
    this.persistQueryService = inject(PersistQueryService);
    this.orderOperationsValidador = inject(OrderOperationsValidadorService);
    this.insightsService = inject(InsightsService);
    this.destroyRef$ = new Subject<void>();
    this.orders$ = new BehaviorSubject<AsyncState<Order[]>>({ loading: true });
    this.lastQuery = {
      sort: 'audit.createdAt,desc',
      ...PaginationInitialState,
      size: 36,
    };
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.currencyPipe = new CurrencyPipe('pt');
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
    this.insightsService.clear();
  }

  ngOnInit() {
    const persitedQuery = this.persistQueryService.read<OrderQuery>('peca-order-list');
    const query = persitedQuery || this.lastQuery;

    this.persistQueryService.rewriteQueryString('peca-order-list', query);
    this.fetchOrders(query);
  }

  fetchOrders(query: OrderQuery) {
    this.lastQuery = query;
    this.orders$.next({ loading: true });
    combineLatest([this.orderService.fetch(query), this.orderService.fetchSummaries(query)]).subscribe({
      next: this.onFetchOrdersSuccess.bind(this),
      error: this.onFetchOrdersFailure.bind(this),
    });
  }

  pushInsights(insights?: any) {
    this.insightsService.push([
      {
        type: 'text',
        label: 'Total de pedidos',
        value: insights?.totalOrders || '-',
      },
      {
        type: 'currency',
        label: 'Valor total',
        value: insights?.amount || '0',
      },
    ]);
  }

  onFetchOrdersSuccess(response: [result: Pageable<Order>, summary: any]) {
    const [orders, summary] = response;
    const { items, pageSize, currentPage, totalPages, totalItems } = orders;
    this.pagination$.next({
      size: pageSize,
      page: currentPage + 1,
      total: totalPages,
      items: totalItems,
    });
    this.orders$.next({ data: items });
    this.pushInsights(summary);
  }

  onFetchOrdersFailure(e: unknown) {
    this.orders$.next({ failure: true });
  }

  onClickExpand(order: Order) {
    const orders = this.orders$.value.data || [];
    const data = orders.map((item) => ({
      ...item,
      expanded: item.id === order.id && !order.expanded,
    }));

    this.orders$.next({ data });
  }

  onSearch(orderQuery: OrderQuery) {
    const { search, buyerId, status, startDate, endDate, salesChannelId, buyer, externalBuyerDocument, externalReference } = orderQuery;
    const query = {
      sort: 'audit.createdAt,desc',
      ...PaginationInitialState,
      size: 36,
      search,
      buyerId,
      status,
      startDate,
      endDate,
      salesChannelId,
      externalBuyerDocument,
      externalReference,
    };
    this.persistQueryService.rewriteQueryString('peca-order-list', { ...query, buyer });
    this.pushInsights();
    this.fetchOrders(query);
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchOrders(query);
  }

  onClickDetails(order: Order) {
    this.router.navigate(['vendas', order.id]);
  }

  onClickRefresh() {
    this.orders$.next({ loading: true });
    this.pushInsights();
    this.fetchOrders(this.lastQuery);
  }

  onClickPreviewInvoice(order: Order) {
    this.orderService.dowloadInvoice(order.invoice.id).subscribe((res) => {
      this.documentPreview.open({ id: order.id, title: order.commerceReference, type: `application/pdf`, url: res });
    });
  }
}
