import { Component, EventEmitter, inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { AnalyticsService, numeric, ToastService } from '@peca/commons';
import { ModalComponent } from '@peca/design-system';
import { Async } from '@peca/backoffice/utils/async.util';
import { map, Subject, takeUntil } from 'rxjs';
import { Order } from '../../models/order.model';
import { OrderService } from '../../services/order.service';
import { OrderProduct } from '../../models/order-product.modal';

@Component({
  selector: 'peca-order-change-prices-modal',
  templateUrl: './order-change-prices.component.html',
  styleUrls: ['./order-change-prices.component.scss'],
})
export class OrderChangePricesComponent implements OnDestroy {
  @ViewChild('modal')
  modal!: ModalComponent;

  @Output()
  adjust: EventEmitter<void>;

  order: Order | null;

  orderService: OrderService;

  products: Async<OrderProduct[]>;

  destroyRef$: Subject<void>;

  toast: ToastService;

  analytics: AnalyticsService;

  originalFreightPrice: number | null;

  constructor() {
    this.adjust = new EventEmitter<void>();
    this.order = null;
    this.orderService = inject(OrderService);
    this.products = new Async<OrderProduct[]>();
    this.destroyRef$ = new Subject<void>();
    this.toast = inject(ToastService);
    this.analytics = inject(AnalyticsService);
    this.originalFreightPrice = null;
  }

  ngOnDestroy(): void {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  open(order: Order) {
    this.order = order;
    this.fetchProducts(order.id);
    this.originalFreightPrice = order.freightAmount;
    this.modal.open();
  }

  close() {
    this.order = null;
    this.originalFreightPrice = null;
    this.products.clear();
    this.modal.close();
  }

  fetchProducts(orderId: string) {
    this.products.loading();
    this.orderService
      .fetchProductsFromOrder(orderId)
      .pipe(
        takeUntil(this.destroyRef$),
        map((products) => products.map((product) => ({ ...product, originalUnitPrice: product.unitPrice })))
      )
      .subscribe({
        next: (products) => this.products.loaded(products),
        error: () => this.products.failed(),
      });
  }

  onClickCancel() {
    this.close();
  }

  onClickSubmit() {
    if (!this.products.data || !this.order) return;

    const products = this.products.data as (OrderProduct & { originalUnitPrice: number })[];
    const newTotalShippingPrice = this.parseToNumber(this.order.freightAmount);
    const productsWithNewPrice = products
      .filter((product) => this.parseToNumber(product.unitPrice) !== product.originalUnitPrice)
      .map(({ id, unitPrice, originalUnitPrice }) => ({ id, newPrice: this.parseToNumber(unitPrice), originalPrice: originalUnitPrice }));

    if (productsWithNewPrice.some((p) => p.newPrice < 0) || newTotalShippingPrice < 0) {
      this.toast.failure('Não é possível alterar preços para valores negativos.');
      return;
    }

    this.orderService.updatePrices(this.order.id, { products: productsWithNewPrice, newTotalShippingPrice }).subscribe({
      next: () => {
        this.analytics.callback('order-change-prices', {
          order: this.order?.id,
          products: productsWithNewPrice,
          freight: { newPrice: newTotalShippingPrice, originalPrice: this.originalFreightPrice },
        });
        this.toast.success('Preços atualizados com sucesso.');
        this.adjust.next();
        this.close();
      },
      error: () => this.toast.failure('Falha ao alterar preços do pedido.'),
    });
  }

  parseToNumber(value: string | number) {
    return typeof value === 'number' ? value : numeric(value);
  }
}
