import { inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsSeller, WebhookSetting } from '../../models/seller-settings.model';

export class SellerSettingForm {
  private formBuilder: FormBuilder;
  readonly group: FormGroup;

  constructor() {
    this.formBuilder = inject(FormBuilder);

    this.group = new FormGroup({
      clientId: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z -]*$')]),
      clientSecret: new FormControl(''),
      host: new FormControl(''),
      username: new FormControl(''),
      password: new FormControl(''),
      type: new FormControl(''),
      webHooks: this.formBuilder.array([
        this.formBuilder.group({
          id: new FormControl(''),
          service: new FormControl('', [Validators.required]),
          url: new FormControl('', [Validators.required]),
        }),
      ]),
    });
  }

  setFTPCredentialsRequired(required: boolean) {
    const controlsName = ['host', 'username', 'password', 'type'];

    for (const controlName of controlsName) {
      const control = this.group.get(controlName) as FormControl;
      if (required) {
        control.setValidators([Validators.required]);
      } else {
        control.removeValidators([Validators.required]);
      }
      control.updateValueAndValidity();
    }
  }

  get disabled() {
    return this.group.disabled;
  }

  disableForm() {
    this.group.disable();
  }

  get enabled() {
    return this.group.enabled;
  }

  enableForm() {
    this.group.enable();

    if (!this.controls.clientId.value) {
      return this.controls.clientSecret.disable();
    }

    this.controls.clientId.disable();
  }

  get controls() {
    return {
      clientId: this.group.get('clientId') as FormControl,
      clientSecret: this.group.get('clientSecret') as FormControl,
      host: this.group.get('host') as FormControl,
      username: this.group.get('username') as FormControl,
      password: this.group.get('password') as FormControl,
      type: this.group.get('type') as FormControl,
      webHooks: this.group.get('webHooks') as FormArray,
    };
  }

  get values() {
    const formValue = this.group.value;

    const webHooks = (this.group.get('webHooks') as FormArray).controls.map((control) => {
      const groupValue = control.value;
      return {
        id: groupValue?.id,
        service: groupValue?.service,
        url: groupValue?.url,
      };
    });
    return {
      clientId: formValue?.clientId,
      clientSecret: formValue?.clientSecret,
      host: formValue?.host,
      username: formValue?.username,
      password: formValue?.password,
      type: formValue?.type,
      webHooks: webHooks,
    };
  }

  setValue(value: SettingsSeller) {
    this.controls.clientId.setValue(value?.apiCredential?.clientId);
    this.controls.clientSecret.setValue(value?.apiCredential?.clientSecret);
    this.controls.host.setValue(value.ftpCredential?.host);
    this.controls.username.setValue(value.ftpCredential?.username);
    this.controls.password.setValue(value.ftpCredential?.password);
    this.controls.type.setValue(value.ftpCredential?.type);
    this.webhooks = value?.webhooks;
  }

  set webhooks(webhooks: WebhookSetting[]) {
    const webhooksControl = webhooks.map((wh) =>
      this.formBuilder.group({
        id: [wh.id],
        service: [wh.service, Validators.required],
        url: [wh.url, Validators.required],
      })
    );

    this.group.setControl('webHooks', this.formBuilder.array(webhooksControl));
  }

  addNewWebHook() {
    const newWebHook = this.formBuilder.group({
      id: [''],
      service: ['', Validators.required],
      url: ['', Validators.required],
    });

    this.controls.webHooks.push(newWebHook);
  }
}
