import { Pipe, PipeTransform } from '@angular/core';
import { PurchaseOrderStatus } from '../models/purchase-order-status.model';

@Pipe({ name: 'statusColor' })
export class PurchaseOrderStatusColorPipe implements PipeTransform {
  transform(value: PurchaseOrderStatus) {
    switch (value) {
      case 'WAITING_CONFIRMATION':
      case 'IN_SEPARATION':
      case 'AWAITING_COLLECTION':
      case 'DISPATCHED':
      case 'SEPARATED':
      case 'PAYMENT_REFUSED':
      case 'PENDING_PAYMENT':
        return 'default';
      case 'REFUSED':
        return 'primary';
      case 'CANCELED':
        return 'terciary';
      case 'DELIVERED':
        return 'secondary';
      default:
        return 'default';
    }
  }
}
