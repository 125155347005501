import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Async } from '@peca/backoffice/utils/async.util';
import { BreadcrumbItem, KeyTab, Tab } from '@peca/design-system';
import { AnalyticsService, DocumentPreviewComponent, ToastService } from '@peca/commons';
import { Component, inject, OnDestroy, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

import { OrdersReturnService } from '../../services/orders-return.service';
import { OrderReturnDetails, OrderReturnFile } from '../../models/orders-return-details.model';
import { OrderReturnFormModalComponent } from '../orders-return-form-modal/orders-return-form-modal.component';
import { OrderReturnRequest } from '../../models/orders-return-request.model';
import { FileUploadComponent } from '@peca/backoffice/components/file-upload/file-upload.component';

@Component({
  selector: 'peca-orders-return-details',
  templateUrl: './orders-return-details.component.html',
  styleUrls: ['./orders-return-details.component.scss'],
})
export class OrdersReturnDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('form')
  orderFormModal!: OrderReturnFormModalComponent;

  @ViewChild('documentPreview')
  documentPreview!: DocumentPreviewComponent;

  @ViewChild('upload')
  upload!: FileUploadComponent;

  breadcrumb: BreadcrumbItem[];
  order: Async<OrderReturnDetails>;
  destroyRef$: Subject<void>;
  ordersReturnService: OrdersReturnService;
  route: ActivatedRoute;
  analytics: AnalyticsService;
  tabs: KeyTab;
  toast: ToastService;
  confirmable: ConfirmableActionService;

  constructor() {
    this.breadcrumb = [{ label: 'Devoluções', path: '/devolucoes' }];
    this.order = new Async<OrderReturnDetails>();
    this.destroyRef$ = new Subject<void>();
    this.ordersReturnService = inject(OrdersReturnService);
    this.route = inject(ActivatedRoute);
    this.analytics = inject(AnalyticsService);
    this.tabs = {
      products: { label: 'Produtos', path: 'produtos', roles: [] },
      logs: { label: 'Logs', path: 'logs', roles: [] },
    };
    this.toast = inject(ToastService);
    this.confirmable = inject(ConfirmableActionService);
  }

  get id() {
    return this.route.snapshot.params['id'] as string;
  }

  get selectedTab() {
    const tab = Object.values<Tab>(this.tabs).find(({ active }) => active);

    if (!tab) return this.tabs[0];

    return tab;
  }

  get methods() {
    return {
      creditRefund: this.order.data?.creditRefund,
      isBankSlip: this.order.data?.paymentMethod.referenceName === 'bank-slip_0',
      cancelation: !!this.order.data?.cancelationRefundAmount,
      manual: !!this.order.data?.manualRefundAmount,
      credit: !!this.order.data?.creditRefundAmount,
      remaining: !!this.order.data?.remainingAmount,
    };
  }

  ngOnInit() {
    const id = this.id;
    this.fetchDetails(id);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchDetails(id: string) {
    this.order.loading();
    this.ordersReturnService.fetchOrdersReturDetails(id).subscribe({
      next: (response) => {
        this.breadcrumb = [{ label: 'Devoluções', path: '/devolucoes' }, { label: response.order.commerceReference }];
        this.order.loaded(response);
      },
      error: () => {
        this.order.failed();
      },
    });
  }

  updateOrderReturn(id: string, payload: OrderReturnRequest) {
    this.ordersReturnService.updateOrderReturn(id, payload).subscribe({
      next: () => {
        this.orderFormModal.close();
        this.toast.success('Pedido de devolução atualizado com sucesso.');
        this.fetchDetails(id);
      },
      error: () => {
        this.toast.failure('Falha ao atualizar pedido de devolução.');
      },
    });
  }

  onRetryEvent() {
    this.fetchDetails(this.id);
  }

  onTabSelected(tab: Tab) {
    this.selectedTab.active = false;
    tab.active = true;
  }

  onClickAttach() {
    this.upload.open();
  }

  onDeleteEvent(res: { id: string; title: string }) {
    if (!this.order.data) return;
    const { id } = this.order.data;

    this.confirmable.confirm(`Você está removendo o arquivo "${res.title}".`, res.id, true).subscribe({
      next: ({ confirmed }) => {
        if (!confirmed) return;
        this.ordersReturnService.deleteReturnFile(id, res.id).subscribe({
          next: () => {
            this.toast.success('Arquivo removido com sucesso.');
            this.fetchDetails(id);
          },
          error: () => {
            this.toast.failure('Falha ao remover o arquivo.');
          },
        });
      },
    });
  }

  onClickOpenForm() {
    if (!this.order.data) return;
    this.orderFormModal.open(this.order.data);
  }

  onSubmitForm(payload: OrderReturnRequest) {
    if (!this.order.data) return;
    const { paid } = payload;

    if (!paid) {
      this.updateOrderReturn(this.order.data.id, payload);
      return;
    }

    this.confirmableUpdateOrderReturn(payload);
    return;
  }

  confirmableUpdateOrderReturn(payload: OrderReturnRequest) {
    const msg = `Você está prestes a realizar a devolução deste item. <br>
    ⚠️ Atenção: Esta é uma ação <b>permanente</b> e <b>irreversível</b>. Após a confirmação, não será possível recuperar o status atual do item.`;

    this.confirmable.confirm(msg, payload).subscribe({
      next: ({ confirmed }) => {
        if (!confirmed || !this.order.data) return;
        this.updateOrderReturn(this.order.data.id, payload);
      },
      error: () => {
        this.toast.failure('Falha ao atualizar o pedido de devolução.');
      },
    });
  }

  onClickPreview(file: OrderReturnFile) {
    if (!this.order.data) return;

    if (file.status !== 'UPLOADED') {
      this.toast.warning('O arquivo ainda está sendo processado.');
      return;
    }

    this.ordersReturnService.downloadReturnFile(this.order.data.id, file.id).subscribe((res) => {
      this.documentPreview.open({ id: file.id, title: file.name, type: file.contentType, url: res });
    });
  }

  onUploadFile(file: File) {
    if (!this.order.data) return;

    const { id } = this.order.data;

    this.ordersReturnService.uploadReturnFile(id, file).subscribe({
      next: () => {
        this.toast.success('Arquivo anexado com sucesso.');
        this.fetchDetails(id);
      },
      error: () => {
        this.toast.failure('Falha ao anexar o arquivo.');
      },
    });
  }
}
