import { PageableQuery } from '@peca/backoffice/models/pageable-query.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { BaseHttpClient } from '@peca/commons';

import { environment } from 'apps/backoffice/src/environments/environment';
import { ShippingListResponse } from '../models/shipping-list-response.model';
import { map } from 'rxjs';

export class ShippgingService extends BaseHttpClient {
  private readonly api;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1/shippings`;
  }

  fetchShippings(query: PageableQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<ShippingListResponse>>(this.api, { params });
  }

  suggestCarriers(search: string) {
    return this.http
      .get<any[]>(`${environment.gateway.url}/backoffice/v1/carriers/suggestions`, { params: { search } })
      .pipe(map((items) => items.map(({ id, name }) => ({ id, label: name })).sort((x, y) => (x.label > y.label ? 1 : -1))));
  }
}
