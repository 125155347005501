import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { Bill } from '../models/bill.model';
import { BuyerBillQuery } from '../models/buyer-bills-query.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { environment } from 'apps/backoffice/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BillService extends BaseHttpClient {
  fetchBills(id: string, query: BuyerBillQuery) {
    const params = this.prepareQuery(query);
    const bills = this.http.get<Pageable<Bill>>(`${environment.gateway.url}/backoffice/v1/buyers/${id}/bills`, { params });

    return bills;
  }
}
