import { Pipe, PipeTransform } from '@angular/core';
import { WebHookService } from '../models/seller-settings.model';

@Pipe({
  name: 'webHookService',
})
export class WebHookServicePipe implements PipeTransform {
  transform(value: WebHookService): string {
    switch (value) {
      case 'NEW_PURCHASE_ORDER':
        return 'Novo Pedido de Compra';
      case 'CANCELED_PURCHASE_ORDER':
        return 'Pedido de Compra Cancelado';
      default:
        return value;
    }
  }
}
