import { BehaviorSubject, Subject } from 'rxjs';
import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { BreadcrumbItem, Pagination } from '@peca/design-system';
import { PageableQuery } from '@peca/backoffice/models/pageable-query.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, PersistQueryService } from '@peca/commons';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { PurchaseOrderQuery } from '../../models/purchase-order-query.model';
import { PurchaseOrder } from '../../models/purchase-order.model';
import { PurchaseOrderService } from '../../services/purchase.service';
import { OrderPurchase } from '../../../orders/models/order-purchase.model';

@Component({
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss'],
})
export class PurchaseOrderListComponent implements OnInit {
  breadcrumb: BreadcrumbItem[];
  form: FormGroup;
  orders$: BehaviorSubject<AsyncState<PurchaseOrder[]>>;
  lastQuery: PageableQuery;
  pagination$: Subject<Pagination>;
  service: PurchaseOrderService;
  router: Router;
  route: ActivatedRoute;
  toast: ToastService;
  persistQueryService: PersistQueryService;
  private readonly searchFormInitialValue = {
    search: '',
    status: '',
  };

  constructor() {
    this.breadcrumb = [{ label: 'Compras', path: '/compras' }];
    this.form = new FormGroup({ search: new FormControl(''), status: new FormControl('') });
    this.orders$ = new BehaviorSubject<AsyncState<PurchaseOrder[]>>({ loading: true });
    this.lastQuery = { page: 0, size: 12, sort: 'audit.createdAt,desc' };
    this.pagination$ = new Subject<Pagination>();
    this.service = inject(PurchaseOrderService);
    this.router = inject(Router);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.persistQueryService = inject(PersistQueryService);
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, status } = JSON.parse(atob(params['q']));
      this.form.patchValue({
        search: search || '',
        status: status || '',
      });
    });
    const persitedQuery = this.persistQueryService.read<PurchaseOrderQuery>('peca-purchase-order-list');
    const query = persitedQuery || this.lastQuery;
    this.persistQueryService.rewriteQueryString('peca-purchase-order-list', query);

    this.fetchOrders(query);
  }

  fetchOrders(query: PurchaseOrderQuery) {
    this.lastQuery = query;
    this.orders$.next({ loading: true });
    this.service.fetchOrders(query).subscribe({
      next: (page: Pageable<PurchaseOrder>) => {
        this.pagination$.next({
          size: page.pageSize,
          page: page.currentPage + 1,
          total: page.totalPages,
          items: page.totalItems,
        });
        this.orders$.next({ data: page.items });
      },
      error: (e: unknown) => {
        this.orders$.next({ failure: true });
      },
    });
  }

  onSubmit() {
    const query = { ...this.lastQuery, ...this.form.value };
    this.persistQueryService.rewriteQueryString('peca-purchase-order-list', query);
    this.fetchOrders(query);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onSubmit();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    this.form.markAsDirty();
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchOrders(query);
  }

  onClickDetails(order: OrderPurchase) {
    this.router.navigate(['compras', order.id]);
  }

  onClickDownloadNFe(purchase: OrderPurchase) {
    this.service.downloadNFe(purchase.order.id, purchase.id).subscribe({
      next: (url: unknown | null) => {
        if (!url || typeof url !== 'string') {
          this.toast.failure('Este pedido ainda não possúi uma NFe.');
          return;
        }

        window.open(url);
      },
      error: (err) => {
        this.toast.failure('Não foi possível baixar a NFe.');
      },
    });
  }
}
