import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@peca/commons';

export class OrderReturnForm {
  private _group: FormGroup;

  constructor() {
    this._group = new FormGroup({
      bankSlipPayment: new FormControl(false),
      isThirdPartyTransferPayment: new FormControl(false),
      creditRefund: new FormControl(true),
      customerHavePart: new FormControl(false),
      category: new FormControl('', [Validators.required]),
      returned: new FormControl([]),
      justification: new FormControl('', [Validators.required]),
      reason: new FormControl('', [Validators.required]),
    });

    this.addWarrantyValidation();
    this.addCreditRefundValidation();
  }

  get group() {
    return this._group;
  }

  get controls() {
    return {
      bankSlipPayment: this.group.get('bankSlipPayment') as FormControl,
      isThirdPartyTransferPayment: this.group.get('isThirdPartyTransferPayment') as FormControl,
      creditRefund: this.group.get('creditRefund') as FormControl,
      customerHavePart: this.group.get('customerHavePart') as FormControl,
      category: this.group.get('category') as FormControl,
      returned: this.group.get('returned') as FormControl,
      justification: this.group.get('justification') as FormControl,
      reason: this.group.get('reason') as FormControl,
    };
  }

  get invalid() {
    const returned = this._group.get('returned') as FormControl;
    return this._group.invalid || returned.value.length === 0;
  }

  get returned() {
    const returned = this._group.get('returned') as FormControl;
    return returned.value as any[];
  }

  set returned(products: any[]) {
    const returned = this._group.get('returned') as FormControl;
    returned.setValue(products || []);
  }

  findProductReturned(target: { id: string }) {
    const returned = this._group.get('returned') as FormControl;
    return returned.value.find((p: { id: string }) => p.id === target.id);
  }

  addProductReturned(product: any) {
    const returned = this.returned;
    returned.push(product);
    this.returned = returned;
  }

  private addCreditRefundValidation() {
    const category = this.group.get('category') as FormControl;
    const creditRefund = this.group.get('creditRefund') as FormControl;

    category.valueChanges.subscribe((value) => {
      const bankSlipPayment = this.controls.bankSlipPayment;
      const isThirdPartyTransferPayment = this.controls.isThirdPartyTransferPayment.value;

      if (isThirdPartyTransferPayment) {
        creditRefund.setValue(false);
        creditRefund.disable();
        return;
      }

      if (value !== 'EXCHANGE_WARRANTY' && value !== 'EXCHANGE' && !bankSlipPayment.value) {
        creditRefund.enable();
        return;
      }

      creditRefund.setValue(true);
      creditRefund.disable();
    });
  }

  private addWarrantyValidation() {
    const category = this.group.get('category') as FormControl;
    const customerHavePart = this.group.get('customerHavePart') as FormControl;

    customerHavePart.valueChanges.subscribe(() => category.updateValueAndValidity());
    category.addValidators(() => {
      if (category.value !== 'EXCHANGE_WARRANTY' || JSON.parse(customerHavePart.value)) {
        return null;
      }

      return { category: { message: 'Devoluções por garantia só são permitidas quando o cliente está com a peça.' } };
    });
  }
}
