import { Component, EventEmitter, inject, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { SellerQuery } from '../../services/seller-query.model';
import { ActivatedRoute } from '@angular/router';
import { PersistQueryService } from '@peca/commons';

@Component({
  selector: 'peca-seller-list-search',
  templateUrl: './seller-list-search.component.html',
  styleUrls: ['./seller-list-search.component.scss'],
})
export class SellerListSearchComponent {
  @Output()
  readonly filter: EventEmitter<SellerQuery>;

  readonly form: FormGroup;
  private readonly searchFormInitialValue = {
    search: '',
    salesModel: '',
    active: '',
    sort: 'audit.createdAt,asc',
  };
  persistQueryService: PersistQueryService;
  route: ActivatedRoute;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.persistQueryService = inject(PersistQueryService);
    this.filter = new EventEmitter<SellerQuery>();
    this.form = new FormGroup({
      search: new FormControl(''),
      salesModel: new FormControl(''),
      active: new FormControl(''),
      sort: new FormControl('audit.createdAt,asc'),
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, salesModel, active, sort } = JSON.parse(atob(params['q']));
      this.form.patchValue({ search, salesModel, active, sort });
    });
  }

  onSubmitSearch() {
    this.filter.emit(this.form.value);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onSubmitSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }
}
