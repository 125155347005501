import { Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService, PersistQueryService } from '@peca/commons';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { VoucherService } from '../../services/voucher.service';
import { VoucherQuery } from '../../models/voucher-request.model';
import { Pagination, PaginationInitialState, BreadcrumbItem } from '@peca/design-system';
import { Pageable } from '../../../../core/models/pageable.model';
import { AsyncState } from '../../../../core/models/async-state.model';
import { Voucher } from '../../models/voucher.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmableActionService } from '../../../../core/services/confirmable-action.service';

@Component({
  selector: 'peca-vouchers-list',
  templateUrl: './vouchers-list.component.html',
  styleUrls: ['./vouchers-list.component.scss'],
})
export class VouchersListComponent implements OnInit {
  destroyRef$ = new Subject<void>();
  router = inject(Router);
  route = inject(ActivatedRoute);
  toast = inject(ToastService);
  service: VoucherService;
  persistQueryService = inject(PersistQueryService);
  confirmable = inject(ConfirmableActionService);
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Listagem de Cupons', path: '/cupons' }];
  vouchers$ = new BehaviorSubject<AsyncState<Pageable<Voucher>>>({ loading: true });
  pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
  searchForm = new FormGroup({
    search: new FormControl(''),
    campaignDate: new FormControl(''),
    active: new FormControl(''),
  });
  private readonly searchFormInitialValue = {
    search: '',
    campaignDate: '',
    active: '',
  };
  lastQuery: VoucherQuery = { sort: 'id,asc', ...PaginationInitialState };
  constructor() {
    this.service = inject(VoucherService);
  }

  ngOnInit() {
    this.searchForm.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, campaignDate, active } = JSON.parse(atob(params['q']));

      this.searchForm.patchValue({
        search: search || '',
        campaignDate: campaignDate || '',
        active: active || '',
      });
    });

    const persistedQuery = this.persistQueryService.read<VoucherQuery>('peca-vouchers-list');
    const query = persistedQuery || this.lastQuery;
    this.fetch(query);
  }

  handlePaginateEvent(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetch(query);
  }

  fetch(query: VoucherQuery) {
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-vouchers-list', this.lastQuery);
    this.service
      .fetch(this.lastQuery)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: (data) => {
          this.paginate(data);
          this.vouchers$.next({ data });
        },
        error: () => {
          this.vouchers$.next({ failure: true });
        },
      });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  onChangeVoucherStatus(voucher: Voucher) {
    this.confirmable.confirm('Você está alterando o status de um cupom.').subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        voucher.active = !voucher.active;
        return;
      }

      this.service
        .updateStatus(voucher.id, voucher.active)
        .pipe(takeUntil(this.destroyRef$))
        .subscribe({
          error: (err) => {
            voucher.active = !voucher.active;
            this.toast.failure('Ocorreu um erro ao alterar o status do cupom');
          },
          complete: () => {
            this.toast.success('Status do cupom alterado com sucesso');
          },
        });
    });
  }

  onClickDetails(voucher: Voucher) {
    this.router.navigate(['/cupons', voucher.id]);
  }

  onSubmitSearch() {
    if (this.searchForm.invalid) {
      return this.toast.failure('Filtro incorreto');
    }
    const { active, search, campaignDate } = this.searchForm.value;
    const query = { ...this.lastQuery, search, active, campaignDate };
    this.persistQueryService.rewriteQueryString('peca-vouchers-list', query);
    this.fetch(query as VoucherQuery);
  }

  clearFilters() {
    this.searchForm.reset(this.searchFormInitialValue);
    this.onSubmitSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.searchForm.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.searchForm.markAsPristine();
    return this.searchForm.markAsDirty();
  }
}
