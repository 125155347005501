import { Pipe, PipeTransform } from '@angular/core';
import { PurchaseOrderStatus } from '../models/purchase-order-status.model';

@Pipe({ name: 'status' })
export class PurchaseOrderStatusPipe implements PipeTransform {
  transform(value: PurchaseOrderStatus) {
    switch (value) {
      case 'WAITING_CONFIRMATION':
        return 'Aguardando confirmação';
      case 'IN_SEPARATION':
        return 'Em separação';
      case 'AWAITING_COLLECTION':
        return 'Aguardando coleta';
      case 'DISPATCHED':
        return 'Despachado';
      case 'REFUSED':
        return 'Recusado';
      case 'CANCELED':
        return 'Cancelado';
      case 'DELIVERED':
        return 'Entregue';
      case 'SEPARATED':
        return 'Separado';
      case 'PENDING_PAYMENT':
        return 'Aguardando Pagamento';
      case 'PAYMENT_REFUSED':
        return 'Pagamento Recusado';
      default:
        return value;
    }
  }
}
